import React from "react";
import Layout from "../../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
const isoPhobe = () => (
    <Layout>
        <Row>
		<Col>
			<h1>Isophobe Product Details</h1>
			<p></p>
			<Table >
				<tbody>
					<tr>
						<td>Instructions:</td>
						<td>Clean the surface by chipping / air blower / industrial cleaner followed by high pressure water - jet cleaning. Always dilute ISOPHOBE with clean water. Avoid hard water. Use the diluted solution within 4-8 hours. Do not store. Apply ISOPHOBE till saturation</td>
					</tr>
					<tr>
						<td>Test:</td>
						<td>Scratch 0.5 to 1mm ISOPHOBE treated surface with a blade / knife. Put a drop water on the scratched surface as well as on the scratched powder material.Dry surface / powder confirms waterproofing.</td>
					</tr>
					<tr>
						<td>Storage:</td>
						<td>Store in shady area away from direct sunlight between 5 ℃ to 45 ℃. Keep away from heat, ignition / sparks and from rain / standing water. Prevent the products from freezing. Keep the container of ISOPHOBE tightly colsed after every withdrawal when not in use. (product can start reacting with moisture in the ambient).</td>
					</tr>
					<tr>
						<td>Shelf Life:</td>
						<td>ISOPHOBE 24 months when stored as recommended.</td>
					</tr>
                    <tr>
                        <td>Application:</td>
                        <td>Basement, Balconies, Elevator, Utility Areas, Podiums, Terraces, Toilets, Overhead Tanks, Swimming Pool / STP.</td>
                    </tr>
                    <tr>
                        <td>Appearance:</td>
                        <td>Milky White Liquid.</td>
                    </tr>
                    <tr>
                        <td>Viscosity(25℃):</td>
                        <td>20 - 100cps</td>
                    </tr>
                    <tr>
                        <td>Specific Gravity:</td>
                        <td>0.91 +_ 0.02</td>
                    </tr>
                    <tr>
                        <td>Packaging:</td>
                        <td>1Ltr, 5Ltrs, 20Ltrs.</td>
                    </tr>
				</tbody>
			</Table>
		</Col>
        </Row>

    </Layout>

)

export default isoPhobe;